import React, { useState } from 'react'
import Nav from '../../components/Assets/dashNav'
import {
  BiBookmark,
  BiCard,
  BiChevronRight,
  BiTransfer,
  BiLogOutCircle,
} from 'react-icons/bi'
import { RxPinTop } from 'react-icons/rx'
import {
  BsMenuButtonWide,
  BsPersonFill,
  BsCashCoin,
  BsFillCreditCardFill,
} from 'react-icons/bs'
import Left from './Left'
import { Menu, Progress, Space } from 'antd'
import { Button, Dropdown, message, Tooltip } from 'antd'
import { MdArrowDropDown } from 'react-icons/md'
import {
  Bs1Square,
  BsFillQuestionCircleFill,
  BsFillDatabaseFill,
} from 'react-icons/bs'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { setToken, setUserDetails } from '../../Redux/action'
import Footerd from '../../components/Assets/dashFooter'
import History from './History'
import './Transactiom.css'

const Transactions = () => {
  const [email, setEmail] = useState('')
  const [transactions, setTransactions] = useState([])
  const [errorMessage, setErrorMessage] = useState('')

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }

  const handleGetTransactions = () => {
    setErrorMessage('')
    fetch('https://api-white-qfhq.onrender.com/transactions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json()
        } else if (res.status === 404) {
          setTransactions([])
          return Promise.reject(new Error('Transactions not found.'))
        } else {
          setTransactions([])
          return Promise.reject(
            new Error('An error occurred while retrieving user transactions.')
          )
        }
      })
      .then((res) => {
        // Assuming the response contains the transactions array in the "transactions" property.
        // Adjust the code below based on the actual data structure.
        setTransactions(res.transactions)
        console.log('datataa', res.transactions)
      })
      .catch((error) => {
        console.error('Error fetching user transactions:', error)
        setErrorMessage('An error occurred while fetching user transactions.')
      })
  }

  const dispatch = useDispatch()
  let navigate = useNavigate()
  const [menu, setMenu] = useState(false)
  const handleButtonClick = (e) => {
    // message.info("Click on left button.");
    setMenu(Menu)
    console.log('click left button', e)
  }
  const handleMenuClick = (e) => {
    // message.info("Click on menu item.");
    // console.log("click", e);
  }
  const user = useSelector((state) => state.auth.user_details)

  const items = [
    {
      label: (
        <div>
          <Link to='/user/fund-account'>
            <p>Deposit</p>
          </Link>
        </div>
      ),
      key: '1',
      icon: <BsCashCoin />,
    },
    {
      label: (
        <div>
          <Link to='/user/withdraw'>
            <p>Withdrawal</p>
          </Link>
        </div>
      ),
      key: '2',
      icon: <RxPinTop />,
    },
    {
      label: (
        <div>
          <Link to='/user/transfer'>
            <p>Direct Transfer</p>
          </Link>
        </div>
      ),
      key: '3',
      icon: <BiTransfer />,
    },
    {
      label: (
        <div
          onClick={() => {
            dispatch(setToken(''))
            dispatch(setUserDetails({}))
            navigate('/public/login', { replace: true })
          }}>
          logout
        </div>
      ),
      key: '4',
      icon: <BiLogOutCircle />,
    },
  ]
  const menuProps = {
    items,
    onClick: handleMenuClick,
  }

  // Dummy transaction data
  const transactionx = [
    {
      receiver: 'Mohamed Benar',
      type: 'Food',
      date: 'Jun 22, 2023',
      amount: '$15',
      status: 'Pending',
    },
    {
      receiver: 'Kathryn Murphy',
      type: 'Shopping',
      date: 'Jun 21, 2022',
      amount: '$25',
      status: 'Cancelled',
    },
    {
      receiver: 'Mohamed Benar',
      type: 'Food',
      date: 'Jun 22, 2023',
      amount: '$15',
      status: 'Successful',
    },

    // Add more transactions here...
  ]

  function formatDate(dateString) {
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }

    const date = new Date(dateString)
    const formattedDate = date.toLocaleString('en-US', options)

    const day = date.getDate()
    let suffix = 'th'
    if (day === 1 || day === 21 || day === 31) {
      suffix = 'st'
    } else if (day === 2 || day === 22) {
      suffix = 'nd'
    } else if (day === 3 || day === 23) {
      suffix = 'rd'
    }

    return formattedDate.replace(',', `${suffix},`)
  }

  // const inputDate = "2023-07-31T14:05:28.343Z";
  // const formattedDate = formatDate(inputDate);
  // console.log(formattedDate); // Output: "31st July, 2023 - 2:05 PM"
  return (
    <div className='overflow-hidden over'>
      <div>
        <div>
          <Nav />
        </div>

        <div className='md:flex'>
          <div className='w-1/5'>
            <Left />
          </div>

          <section className='flex-1 bg-gray-50'>
            <div>
              <div>
                <h1>Transaction History</h1>
                <table>
                  <thead>
                    <tr>
                      <th>Receiver</th>
                      <th>Type</th>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactionx.map((transaction, index) => (
                      <tr key={index}>
                        <td>{transaction.receiver}</td>
                        <td>{transaction.type}</td>
                        <td>{transaction.date}</td>
                        <td>{transaction.amount}</td>
                        <td className={getStatusClass(transaction.status)}>
                          <span className='status px-3 py-2 rounded-sm text-white'>
                            {transaction.status}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {/* <div className="md:w-[50%] lg:mx-0 mx-5 lg:mt-0 mt-5 flex flex-col text-black">
              <div className="flex justify-between">
                <p className="lg:text-lg text-base font-fira text-[#150761] tracking-tighter">
                  Personal Account Details
                </p>
                <BsFillDatabaseFill className="text-black mt-2" />
              </div>
              <div className="border-y border-gray-200 py-5 mt-10">
                <div>
                  <p className="font-semibold">Name:</p>
                  <p className="text-sm text-gray-500">
                    {user.firstName} {user.lastName}
                  </p>
                </div>
                <div className="pt-3">
                  <p className="font-semibold">Account type:</p>
                  <p className="text-sm text-gray-500">Savings Account</p>
                </div>
                <div className="pt-3">
                  <p className="font-semibold">Account Number:</p>
                  <p className="text-sm text-gray-500">
                    {user.accountNumber}
                  </p>
                </div>
                <div className="pt-3">
                  <p className="font-semibold">Account Balance:</p>
                  <p className="text-sm text-gray-500">${user.balance}</p>
                </div>
              </div>
              <p className="mt-5 text-sm">Registered: 27th Jul, 2023</p>
            </div> */}
            <Footerd />
          </section>
        </div>
      </div>
    </div>
  )
}

// Function to determine class based on status
const getStatusClass = (status) => {
  switch (status) {
    case 'Pending':
      return 'pending'
    case 'Cancelled':
      return 'cancelled'
    case 'Successful':
      return 'successful'
    default:
      return ''
  }
}

export default Transactions
