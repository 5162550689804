import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { setUserDetails, setToken } from '../../Redux/action'
import { useDispatch } from 'react-redux'
import { CgProfile } from 'react-icons/cg'
import {
  BsPhoneFill,
  BsFillCalendar2DateFill,
  BsFillHouseCheckFill,
} from 'react-icons/bs'
import { IoPersonCircle } from 'react-icons/io5'
import { Select } from 'antd'
import { GrMail } from 'react-icons/gr'
import { MdOutlineTextFields, MdOutlinePassword } from 'react-icons/md'
import Logo from '../../components/Assets/logo'

const Register = ({ label, ...rest }) => {
  const [isFocused, setIsFocused] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  let navigate = useNavigate()
  const dispatch = useDispatch()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [address, setAddress] = useState('')
  const [dial, setDial] = useState('')
  const [country, setCountry] = useState('')
  const [accountType, setAccountType] = useState('savings')
  const [currency, setCurrency] = useState('')
  const [gender, setGender] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [date, setDate] = useState('')
  const [terms, setTerms] = useState(true)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [msg, setMsg] = useState({
    name: '',
    email: '',
    phone: '',
    dial: '',
    accountType: '',
    password: '',
    confirmPassword: '',
    error: '',
    exists: '',
  })
  const [errors, setErrors] = useState({
    firstNameError: '',
    lastNameError: '',
    dialError: '',
    emailError: '',
    passwordError: '',
    confirmPasswordError: '',
  })

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setErrors((prevErrors) => ({ ...prevErrors, [`${name}Error`]: '' }))
  }

  const handleAccountTypeChange = (e) => {
    setAccountType(e.target.value)
  }
  const register = (e) => {
    e.preventDefault()

    if (!terms) {
      return
    }
    setMsg({})
    console.log({
      firstName,
      lastName,
      address,
      email,
      phone,
      password,
      confirmPassword,
    })

    setErrors({
      firstNameError: '',
      lastNameError: '',
      dialError: '',
      emailError: '',
      passwordError: '',
      confirmPasswordError: '',
    })

    let isValid = true

    if (!firstName) {
      setErrors((prevFormData) => ({
        ...prevFormData,
        firstNameError: 'First Name is required.',
      }))
      isValid = false
    }

    if (!lastName) {
      setErrors((prevFormData) => ({
        ...prevFormData,
        lastNameError: 'Last Name is required.',
      }))
      isValid = false
    }

    if (!dial) {
      setErrors((prevFormData) => ({
        ...prevFormData,
        dialError: 'Dial code is required.',
      }))
      isValid = false
    }

    if (!email) {
      setErrors((prevFormData) => ({
        ...prevFormData,
        emailError: 'Email Address is required.',
      }))
      isValid = false
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setErrors((prevFormData) => ({
        ...prevFormData,
        emailError: 'Invalid email address.',
      }))
      isValid = false
    }

    if (!password) {
      setErrors((prevFormData) => ({
        ...prevFormData,
        passwordError: 'Password is required.',
      }))
      isValid = false
    } else if (password.length < 6) {
      setErrors((prevFormData) => ({
        ...prevFormData,
        passwordError: 'Password must be at least 6 characters long.',
      }))
      isValid = false
    }

    if (!confirmPassword) {
      setErrors((prevFormData) => ({
        ...prevFormData,
        confirmPasswordError: 'Confirm Password is required.',
      }))
      isValid = false
    } else if (password !== confirmPassword) {
      setErrors((prevFormData) => ({
        ...prevFormData,
        confirmPasswordError: 'Passwords do not match.',
      }))
      isValid = false
    }
    // console.log("rrrrr", confirmPassword);

    if (!terms) {
      isValid = false
      // You may display a message or highlight the checkbox here
    }

    if (isValid) {
      // Your registration logic here...
      fetch('https://api-white-qfhq.onrender.com/sign-up', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          password,
          dial,
          accountType,
          address,
          date,
          currency,
        }),
      })
        .then((response) => response.json())
        .then((res) => {
          console.log('res', res)
          const { token } = res
          const { user } = res
          dispatch(setToken(token))

          if (res.msg) {
            setMsg(res.msg)
            console.log('msg', msg)
          }

          if (token != undefined) {
            dispatch(setUserDetails(user))
            navigate('/user/dashboard', { replace: true })
          }
        })
        .catch((err) => console.log(err))
    }
  }

  return (
    <div className='bg-gray-100 '>
      <div className='flex justify-center lg:py-14 py-10 lg:px-0 px-5 bg-white lg:mx-32'>
        <div className='w-full'>
          <div className='flex lg:gap-[30rem] justify-center w-full'>
            <div>
              <Logo />
            </div>
          </div>
          <div>
            <div className='flex flex-col justify-center lg:mx-[14.25rem]'>
              <h1 className='capitalize py-5 text-sm text-blue-400 font-fira font-semibold'>
                Register Account
              </h1>
              <p className='text-xs text-blue-400'>
                Choose the best account for you and enjoy Online banking, Mobile
                banking, a debit card with total security protection - and much
                more. Apply today in minutes and get a bank account that works
                for you.
              </p>
            </div>
            <form className='lg:mx-[14.25rem] my-5' action=''>
              <div className='grid grid-cols-2 gap-10 text-blue-400'>
                <div className='flex flex-col '>
                  <label className='text-sm'>First Name*</label>
                  <input
                    className='outline-none border border-blue-500 rounded-md px-5 text-black py-1 text-sm focus:'
                    required
                    name='firstName'
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder='First Name'
                    type='text'
                  />
                  {errors.firstNameError && (
                    <p className='text-red-500 text-xs'>
                      {errors.firstNameError}
                    </p>
                  )}
                </div>
                <div className='flex flex-col'>
                  <label className='text-sm'>Last Name*</label>
                  <input
                    className='outline-none border border-blue-500 rounded-md px-5 text-black py-1 text-sm focus:'
                    required
                    name='lastName'
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder='Last Name'
                    type='text'
                  />
                  {errors.lastNameError && (
                    <p className='text-red-500 text-xs'>
                      {errors.lastNameError}
                    </p>
                  )}
                </div>
              </div>

              <div className='grid grid-cols-2 gap-10 pt-5 text-blue-400'>
                <div className='flex flex-col '>
                  <label className='text-sm'>Email Address*</label>
                  <input
                    className='outline-none border border-blue-500 rounded-md px-5 text-black py-1 text-sm focus:'
                    required
                    name='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder='Email Address'
                    type='email'
                  />
                  {errors.emailError && (
                    <p className='text-red-500 text-xs'>{errors.emailError}</p>
                  )}
                </div>
                <div className='flex flex-col'>
                  <label className='text-sm'>State*</label>
                  <input
                    className='outline-none border border-blue-500 rounded-md px-5 text-black py-1 text-sm focus:'
                    placeholder='State'
                    type='text'
                  />
                </div>
              </div>

              <div className='grid grid-cols-2 gap-10 pt-5 text-blue-400'>
                <div className='flex flex-col '>
                  <label className='text-sm'>Date of Birth*</label>
                  <input
                    className='outline-none border border-blue-500 rounded-md px-5 text-black py-1 text-sm focus:'
                    name='date'
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    type='date'
                  />
                  {errors.dateError && (
                    <p className='text-red-500 text-xs'>{errors.dateError}</p>
                  )}
                </div>
                <div className='flex flex-col'>
                  <label className='text-sm'>Gender</label>
                  <input
                    className='outline-none border border-blue-500 rounded-md px-5 text-black py-1 text-sm focus:'
                    placeholder='eg. Male or female'
                    name='gender'
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    type='text'
                  />
                </div>
              </div>

              <div className='grid grid-cols-2 gap-10 py-5 text-blue-400'>
                <div className='flex flex-col '>
                  <label className='text-sm'>Marital Status</label>
                  <input
                    className='outline-none border border-blue-500 rounded-md px-5 text-black py-1 text-sm focus:'
                    type='text'
                  />
                </div>
                <div className='flex flex-col'>
                  <label className='text-sm'>Dial Code*</label>
                  <input
                    className='outline-none border border-blue-500 rounded-md px-5 text-black py-1 text-sm focus:'
                    placeholder='Dial Code'
                    name='dial'
                    value={dial}
                    onChange={(e) => setDial(e.target.value)}
                    type='text'
                  />
                  {errors.dialError && (
                    <p className='text-red-500 text-xs'>{errors.dialError}</p>
                  )}
                </div>
              </div>

              <div className='grid grid-cols-2 gap-10 pb-5 text-blue-400'>
                <div className='flex flex-col '>
                  <label className='text-sm'>Phone*</label>
                  <input
                    className='outline-none border border-blue-500 rounded-md px-5 text-black py-1 text-sm focus:'
                    name='phone'
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    type='text'
                  />
                </div>
                <div className='flex flex-col'>
                  <label className='text-sm'>Professional Status</label>
                  <input
                    className='outline-none border border-blue-500 rounded-md px-5 text-black py-1 text-sm focus:'
                    placeholder='e.g Employed, Unemployed..'
                    type='text'
                  />
                </div>
              </div>

              <div className='flex flex-col'>
                <label className='text-sm text-blue-400'>Address*</label>
                <input
                  className='outline-none border border-blue-500 rounded-md px-5 text-black py-1 text-sm focus:'
                  name='address'
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  placeholder='Enter your address'
                  type='text'
                />
                {errors.addressError && (
                  <p className='text-red-500 text-xs'>{errors.addressError}</p>
                )}
              </div>

              <div className='flex flex-col pt-5'>
                <label className='text-sm text-blue-400'>Account</label>
                <select
                  className='outline-none border border-blue-400 py-1 px-5 text-sm rounded-md text-black'
                  name='accountType'
                  id='please choose an account'
                  value={accountType}
                  onChange={handleAccountTypeChange}>
                  <option value='savings'>Savings Account</option>
                  <option value='fixed'>Fixed Account</option>
                  <option value='premium'>Premium Account</option>
                </select>
              </div>

              <div className='grid grid-cols-2 gap-10 py-5 text-blue-400'>
                <div className='flex flex-col '>
                  <label className='text-sm'>Nationality*</label>
                  <input
                    className='outline-none border border-blue-500 rounded-md px-5 text-black py-1 text-sm focus:'
                    name='country'
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    type='text'
                  />
                </div>
                <div className='flex flex-col'>
                  <label className='text-sm'>Currency</label>
                  <input
                    className='outline-none border border-blue-500 rounded-md px-5 text-black py-1 text-sm focus:'
                    placeholder='$'
                    type='text'
                  />
                </div>
              </div>

              <div className='grid grid-cols-2 gap-10 pb-5 text-blue-400'>
                <div className='flex flex-col '>
                  <label className='text-sm'>Password*</label>
                  <input
                    className='outline-none border border-blue-500 rounded-md px-5 text-black py-1 text-sm focus:'
                    name='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type='password'
                  />
                  {errors.passwordError && (
                    <p className='text-red-500 text-xs'>
                      {errors.passwordError}
                    </p>
                  )}
                </div>
                <div className='flex flex-col'>
                  <label className='text-sm'>Confirm Password*</label>
                  <input
                    className='outline-none border border-blue-500 rounded-md px-5 text-black py-1 text-sm focus:'
                    placeholder='password confirmation'
                    type='password'
                    name='confirmPassword'
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  {errors.confirmPasswordError && (
                    <p className='text-red-500 text-xs'>
                      {errors.confirmPasswordError}
                    </p>
                  )}
                </div>
              </div>

              <button
                onClick={register}
                className='w-full text-center py-1 rounded-md bg-blue-500 text-white'>
                Create account
              </button>

              <div className='text-center text-blue-400 text-sm pt-1'>
                <p>
                  Already have an account?{' '}
                  <span className='text-red-500'>
                    <Link to='/public/login'> login</Link>
                  </span>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register
