import React from "react";

const Footerd = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return (
    <div>
      <footer class="lg:flex lg:space-x-28 text-center bg-gray-50 justify-center z-10 pt-7 pb-5">
        <div class="pull-right d-none d-sm-inline-block">
          <ul class="nav nav-primary nav-dotted nav-dot-separated justify-content-center justify-content-md-end">
            <li className="text-blue-500">Contact US: Email: <span className="text-gray-500">support@whiteoaktrust.com</span></li>
            <li className="text-blue-500">WhatsApp: <span className="text-gray-500">+1 281 202 2744</span></li>
          </ul>
        </div>
        <div className="mt-4">
          Copyright © Whiteoak Trust {currentYear}. All Rights Reserved.
        </div>
      </footer>
    </div>
  );
};

export default Footerd;
